<script>
  import StatusItem from './StatusItem.svelte';

  export let title = '';
  export let description = '';
  export let statusInfo = [];
  export let vehicleInfo;
</script>

<div class="mx-auto w-auto mt-8 text-left 2xl:w-5/12 lg:w-1/2 md:w-9/12">
  <h2 class="text-2xl text-primary font-medium">{title}</h2>
  {#if description}
    <p class="mt-2 mb-0 text-gray-500">
      {description}
    </p>
  {/if}
  <div>
    {#each statusInfo as status, i}
      <StatusItem
        vehicleInfo={i === 0 ? vehicleInfo : null}
        isActive={status.isActive}
        title={status.title}
        description={status.description}
      />
    {/each}
  </div>
</div>
