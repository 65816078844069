<script>
  export let type = '';

  const getStylesByType = () => {
    switch (type) {
      case 'obliquely':
        return 'top:124px;left:50px;transform:rotate(-31deg);';
      case 'side':
        return 'top:120px;left:50%;transform:translateX(-50%);';
      case 'default':
      default:
        return 'top:136px;left:50%;transform:translateX(-50%);';
    }
  };
</script>

<div class="absolute w-4/5 h-3 bg-primary rounded-lg" style={getStylesByType()} />
