export const extractContentString = (CMSContent, key, lang) => {
  const translations = CMSContent.find((el) => el.key === key);
  return translations ? translations[lang] : '';
};
export const formatVehicleInfoToDisplay = (vehicleInfo) => ({
  ...vehicleInfo,
  first_registration_month:
    (vehicleInfo.first_registration_month < 9 ? '0' : '') +
    (vehicleInfo.first_registration_month + 1),
  mileage: vehicleInfo.mileage.toLocaleString().replace(/,|\./g, '’'),
});

export const lightenDarkenColor = (color, amt) => {
  var usePound = false;

  if (color.length === 4) {
    color += color.slice(-3);
  }

  if (color[0] == '#') {
    color = color.slice(1);
    usePound = true;
  }

  var num = parseInt(color, 16);

  var r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  var b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  var g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
};