<script>
  export let logoOnly = false;
  export let logo = null;
  export let title;
  export let description;
  export let vehicleInfo;
</script>

<header>
  <img class="w-32 mb-4" src={logo ? logo : "/assets/logo.svg"} alt="logo" />
  {#if !logoOnly}
    <div class="my-6">
      <h1 class="uppercase text-lg font-semibold">{title}</h1>
      <div
        class="mt-2 flex flex-col justify-between items-start text-gray-500 md:flex-row"
      >
        <p class="mb-0 mr-0 md:mr-6">
          {description}
        </p>
        <div class="mt-2 text-left md:mt-0" style="min-width: 180px;">
          <span class="inline font-medium md:block"
            >{vehicleInfo.make} {vehicleInfo.model}</span
          >
          <span class="font-light md:hidden"> | </span>
          <span class="inline font-light md:block"
            >{vehicleInfo.first_registration_month +
              ' / ' +
              vehicleInfo.first_registration_year} | {vehicleInfo.mileage} KM</span
          >
        </div>
      </div>
    </div>
  {/if}
</header>
