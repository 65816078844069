<script>
  import { CheckCircle } from 'svelte-hero-icons';

  export let isActive = false;
  export let title = '';
  export let description = '';
  export let vehicleInfo;
</script>

<div class="my-6">
  <div
    class="flex items-center {isActive ? 'p-1.5 rounded-md  bg-green-600 text-white' : 'text-gray-500'}"
  >
    <CheckCircle class="mr-2 {isActive ? '' : '-ml-1'} h-6 w-6" />
    <h3 class="text-lg font-medium">{title}</h3>
  </div>
  {#if vehicleInfo}
    <div class="flex flex-col items-start mt-3 sm:flex-row sm:items-center">
      <img
        class="w-32 h-32 object-cover rounded-lg"
        src={vehicleInfo.photo}
        alt=""
      />
      <div class="mt-4 ml-0 sm:ml-6 sm:mt-0">
        <span class="block font-medium"
          >{vehicleInfo.make} {vehicleInfo.model}</span
        >
        <span class="block font-light"
          >{vehicleInfo.first_registration_month +
            ' / ' +
            vehicleInfo.first_registration_year} | {vehicleInfo.mileage} KM</span
        >
      </div>
    </div>
  {/if}
  {#if description}
    <p class="mt-2 mb-0 text-gray-500">
      {description}
    </p>
  {/if}
</div>
