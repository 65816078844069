<script>
  import Step from './Step.svelte';

  export let currentStep = 0;
  export let steps;
  export let onNextStep;
</script>

<nav class="pb-5 hidden md:block" aria-label="Progress">
  <ol
    class="border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0"
  >
    {#each steps as step, i}
      {#if currentStep === i}
        <Step index={i} {step} type="current" />
      {:else if step.isCompleted}
        <Step index={i} {step} type="completed" onClick={() => onNextStep(i)} />
      {:else}
        <Step
          index={i}
          {step}
          type="upcoming"
          onClick={i === steps.findIndex(step => !step.isCompleted)
            ? () => onNextStep(i)
            : null}
        />
      {/if}
    {/each}
  </ol>
</nav>
<nav class="pb-5 text-center md:hidden" aria-label="Progress">
  <ol class="flex justify-center items-center space-x-5">
    {#each steps as step, i}
      {#if currentStep === i}
        <Step index={i} {step} type="current" isMobile />
      {:else if step.isCompleted}
        <Step
          index={i}
          {step}
          type="completed"
          onClick={() => onNextStep(i)}
          isMobile
        />
      {:else}
        <Step
          index={i}
          {step}
          type="upcoming"
          onClick={i === steps.findIndex(step => !step.isCompleted)
            ? () => onNextStep(i)
            : null}
          isMobile
        />
      {/if}
    {/each}
  </ol>
  <div class="mt-5">
    <span class="block text-xs font-semibold uppercase tracking-wide"
      >{steps[currentStep].title}</span
    >
    <span class="text-sm font-medium text-gray-500"
      >{steps[currentStep].description}</span
    >
  </div>
</nav>
