<script>
  import AligmentLine from './AlignmentLine.svelte';
  import { CloudUpload } from 'svelte-hero-icons';

  export let img;
  export let thumb;
  export let title;
  export let description;
  export let alignmentLine = '';
  export let btnText = 'Upload';
  export let onUpload;
</script>

<div
  class="mb-7 mx-4 w-52 text-center rounded-lg shadow cursor-pointer"
  on:click={onUpload}
>
  <div class="p-4 text-sm">
    <div class="relative">
      <img
        class="mb-3 w-44 h-44 object-cover {img
          ? 'opacity-100'
          : 'opacity-70'} rounded-lg"
        src={img ? img : thumb}
        alt=""
      />
      {#if alignmentLine && !img}
        <AligmentLine type={alignmentLine} />
      {/if}
    </div>
    <h2>{title}</h2>
    {#if description}
      <p class="mb-0 text-gray-500">{description}</p>
    {/if}
  </div>
  <div
    class="py-4 inline-flex items-center justify-center w-full text-sm font-medium text-gray-700 bg-transparent border-t border-gray-200"
  >
    <CloudUpload class="mr-3 h-6 w-6 text-gray-400" />
    {btnText}
  </div>
</div>
