import axios from 'axios';
import * as routes from './routes';

const GET_METHOD = 'GET';
const POST_METHOD = 'POST';

// isSecondTry is a temporary solution needed to recall Netlify function once again
const callAPI = (
  path,
  method = GET_METHOD,
  data,
  params,
  isSecondTry = false
) => {
  if (method === POST_METHOD) {
    return axios
      .post(path, JSON.stringify(data), params)
      .then((res) => res.data)
      .catch((err) => {
        if (
          !isSecondTry &&
          (!err.response || err.response.status === 502) &&
          path !== routes.POST_ADDITIONAL_INFO
        ) {
          return callAPI(path, method, data, params, true);
        }

        alert('Something went wrong :(');
      });
  }

  if (method === GET_METHOD) {
    return axios
      .get(path, { params: data })
      .then((res) => res.data)
      .catch((err) => {
        if (!isSecondTry && (!err.response || err.response.status === 502)) {
          return callAPI(path, method, data, params, true);
        }

        alert('Something went wrong :(');
      });
  }
};

export const getCMSContent = (widgetID) =>
  callAPI(routes.GET_CMS_CONTENT, POST_METHOD, widgetID);
export const getAdditionalInfo = (submissionID) =>
  callAPI(routes.GET_ADDITIONAL_INFO, POST_METHOD, submissionID);
export const postAdditionalInfo = (submissionData) =>
  callAPI(routes.POST_ADDITIONAL_INFO, POST_METHOD, submissionData);
