<script>
  export let index;
  export let type;
  export let step;
  export let isMobile = false;
  export let onClick = null;
</script>

{#if isMobile}
  {#if type === 'completed'}
    <li class={onClick ? 'cursor-pointer' : ''} on:click={onClick}>
      <div
        class="block w-2.5 h-2.5 bg-primary rounded-full hover:bg-primary-dark"
      />
    </li>
  {:else if type === 'current'}
    <li>
      <div
        class="relative flex items-center justify-center"
        aria-current="step"
      >
        <span class="absolute w-5 h-5 p-px flex" aria-hidden="true">
          <span class="w-full h-full rounded-full bg-primary-light" />
        </span>
        <span
          class="relative block w-2.5 h-2.5 bg-primary rounded-full"
          aria-hidden="true"
        />
      </div>
    </li>
  {:else}
    <li class={onClick ? ' cursor-pointer' : ''} on:click={onClick}>
      <div class="block w-2.5 h-2.5 bg-gray-200 rounded-full hover:bg-gray-400">
        <span class="sr-only">Step 3</span>
      </div>
    </li>
  {/if}
{:else if type === 'completed'}
  <li
    class="relative overflow-hidden md:flex-1{onClick ? ' cursor-pointer' : ''}"
    on:click={onClick}
  >
    <div
      class="border border-gray-200 overflow-hidden border-b-0 rounded-t-md md:border-0"
    >
      <div class="group">
        <span
          class="absolute top-0 left-0 w-1 h-full bg-transparent md:w-full md:h-1 md:bottom-0 md:top-auto"
          aria-hidden="true"
        />
        <span class="px-6 py-5 flex items-start text-sm font-medium">
          <span class="flex-shrink-0">
            <span
              class="w-10 h-10 flex items-center justify-center bg-primary rounded-full"
            >
              <svg
                class="w-6 h-6 text-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
          </span>
          <span class="mt-0.5 ml-4 min-w-0 flex flex-col">
            <span class="text-xs font-semibold uppercase tracking-wide"
              >{step.title}</span
            >
            <span class="text-sm font-medium text-gray-500"
              >{step.description}</span
            >
          </span>
        </span>
      </div>
    </div>
  </li>
{:else if type === 'current'}
  <li class="relative overflow-hidden md:flex-1">
    <div class="border border-gray-200 overflow-hidden md:border-0">
      <div aria-current="step">
        <span
          class="absolute top-0 left-0 w-1 h-full bg-primary md:w-full md:h-1 md:bottom-0 md:top-auto"
          aria-hidden="true"
        />
        <span class="px-6 py-5 flex items-start text-sm font-medium md:pl-9">
          <span class="flex-shrink-0">
            <span
              class="w-10 h-10 flex items-center justify-center border-2 border-primary rounded-full"
            >
              <span class="text-primary">{index + 1}</span>
            </span>
          </span>
          <span class="mt-0.5 ml-4 min-w-0 flex flex-col">
            <span
              class="text-xs font-semibold text-primary uppercase tracking-wide"
              >{step.title}</span
            >
            <span class="text-sm font-medium text-gray-500"
              >{step.description}</span
            >
          </span>
        </span>
      </div>

      {#if index !== 0}
        <div
          class="hidden absolute top-0 left-0 w-3 inset-0 md:block"
          aria-hidden="true"
        >
          <svg
            class="h-full w-full text-gray-300"
            viewBox="0 0 12 82"
            fill="none"
            preserveAspectRatio="none"
          >
            <path
              d="M0.5 0V31L10.5 41L0.5 51V82"
              stroke="currentcolor"
              vector-effect="non-scaling-stroke"
            />
          </svg>
        </div>
      {/if}
    </div>
  </li>
{:else}
  <li
    class="relative overflow-hidden md:flex-1{onClick ? ' cursor-pointer' : ''}"
    on:click={onClick}
  >
    <div
      class="border border-gray-200 overflow-hidden border-t-0 rounded-b-md md:border-0"
    >
      <div class="group">
        <span
          class="absolute top-0 left-0 w-1 h-full bg-transparent md:w-full md:h-1 md:bottom-0 md:top-auto"
          aria-hidden="true"
        />
        <span class="px-6 py-5 flex items-start text-sm font-medium md:pl-9">
          <span class="flex-shrink-0">
            <span
              class="w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full"
            >
              <span class="text-gray-500">{index + 1}</span>
            </span>
          </span>
          <span class="mt-0.5 ml-4 min-w-0 flex flex-col">
            <span
              class="text-xs font-semibold text-gray-500 uppercase tracking-wide"
              >{step.title}</span
            >
            <span class="text-sm font-medium text-gray-500"
              >{step.description}</span
            >
          </span>
        </span>
      </div>
      <div
        class="hidden absolute top-0 left-0 w-3 inset-0 md:block"
        aria-hidden="true"
      >
        <svg
          class="h-full w-full text-gray-300"
          viewBox="0 0 12 82"
          fill="none"
          preserveAspectRatio="none"
        >
          <path
            d="M0.5 0V31L10.5 41L0.5 51V82"
            stroke="currentcolor"
            vector-effect="non-scaling-stroke"
          />
        </svg>
      </div>
    </div>
  </li>
{/if}
