<script>
  import Question from './Question.svelte';
  import Button from './Button.svelte';
  import { ArrowRight } from 'svelte-hero-icons';

  export let questions;
  export let answers;
  export let getContentString;
  export let handleAnswerSelect;
  export let onSubmit;

  let visibleQuestionAmount = 4;
  const isQuestionAnswered =
    Object.values(answers) &&
    Object.values(answers).length === questions.length;

  const handleShowNextRow = () => {
    if (
      !isQuestionAnswered &&
      Object.values(answers) &&
      Object.values(answers).length === visibleQuestionAmount
    ) {
      visibleQuestionAmount =
        visibleQuestionAmount + 4 > questions.length
          ? questions.length
          : visibleQuestionAmount + 4;
    }
  };
</script>

<div>
  <h3 class="text-lg">
    {!isQuestionAnswered ? visibleQuestionAmount : questions.length} / {questions.length}
    {getContentString('questions_progress')}
  </h3>
  <div class="flex flex-wrap">
    {#each questions.slice(0, !isQuestionAnswered ? visibleQuestionAmount : questions.length) as question, i}
      <Question
        index={i}
        {question}
        value={answers[question.slug]}
        onSelect={handleAnswerSelect}
        controlsTranslation={{
          yes: getContentString('questions_answer_yes'),
          no: getContentString('questions_answer_no'),
          notAvailable: getContentString('questions_answer_not_available'),
        }}
        onShowNextRow={handleShowNextRow}
      />
    {/each}
  </div>
  <div class="mt-3 w-full text-right">
    <Button
      icon={ArrowRight}
      alignIcon="right"
      disabled={Object.values(answers).length !== questions.length}
      onClick={() => {
        onSubmit(0);
      }}>{getContentString('button_next')}</Button
    >
  </div>
</div>
