<script>
  import { ChevronRight } from 'svelte-hero-icons';
  import Fade from './Fade.svelte';
  import Button from './Button.svelte';

  export let isOpen = false;
  export let title = '';
  export let description = '';
  export let btnText = '';
  export let onClose = () => null;
</script>

<Fade show={isOpen} className="fixed top-0 left-0 z-50">
  <div
    class="flex justify-center items-center py-8 px-6 w-screen h-screen bg-gray-900 bg-opacity-80"
  >
    <div class="w-full text-white text-center 2xl:w-5/12 lg:w-1/2 md:w-9/12">
      <h2 class="text-2xl uppercase font-medium">{title}</h2>
      <p class="mt-2 mb-4">{description}</p>
      <Button icon={ChevronRight} alignIcon="right" onClick={onClose}
        >{btnText}</Button
      >
    </div>
  </div>
</Fade>
