<script>
  export let icon;
  export let alignIcon = 'left';
  export let color = 'primary';
  export let textColor = 'white';
  export let disabled = false;
  export let onClick;
</script>

<button
  type="button"
  class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-{textColor} bg-{color} {disabled
    ? 'opacity-50'
    : ''} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-{color}"
  {disabled}
  on:click={onClick}
>
  {#if icon && alignIcon === 'left'}
    <svelte:component this={icon} class="mr-1 -ml-1 h-4 w-4" />
  {/if}
  <slot />
  {#if icon && alignIcon === 'right'}
    <svelte:component this={icon} class="ml-1 -mr-1 h-4 w-4" />
  {/if}
</button>
