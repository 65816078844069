<script>
  export let value;
  export let index;
  export let slug;
  export let onSelect;
  export let optionalAnswer;
  export let controlsTranslation;
</script>

<fieldset class="RadioGroup">
  <div class="bg-white rounded-md -space-y-px">
    <label
      class="border-gray-200 rounded-tl-md rounded-tr-md relative border p-4 flex cursor-pointer"
      on:click={() => onSelect(slug, true)}
    >
      <input
        type="radio"
        name={'answers-radio-group' + index}
        value="true"
        checked={value === true}
        class="h-4 w-4 mt-0.5 cursor-pointer text-primary border-gray-300 focus:ring-primary"
        aria-labelledby="privacy-setting-0-label"
        aria-describedby="privacy-setting-0-description"
      />
      <div class="ml-3 flex flex-col">
        <span
          id="privacy-setting-0-label"
          class="text-gray-900 block text-sm font-medium"
        >
          {controlsTranslation.yes}
        </span>
      </div>
    </label>

    <label
      class={'border-gray-200 relative border p-4 flex cursor-pointer' +
        (!optionalAnswer ? ' rounded-bl-md rounded-br-md' : '')}
      on:click={() => onSelect(slug, false)}
    >
      <input
        type="radio"
        name={'answers-radio-group' + index}
        value="false"
        checked={value === false}
        class="h-4 w-4 mt-0.5 cursor-pointer text-primary border-gray-300 focus:ring-primary"
        aria-labelledby="privacy-setting-1-label"
        aria-describedby="privacy-setting-1-description"
      />
      <div class="ml-3 flex flex-col">
        <span
          id="privacy-setting-1-label"
          class="text-gray-900 block text-sm font-medium"
        >
          {controlsTranslation.no}
        </span>
      </div>
    </label>

    {#if optionalAnswer}
      <label
        class="border-gray-200 rounded-bl-md rounded-br-md relative border p-4 flex cursor-pointer"
        on:click={() => onSelect(slug, null)}
      >
        <input
          type="radio"
          name={'answers-radio-group' + index}
          checked={value === null}
          class="h-4 w-4 mt-0.5 cursor-pointer text-primary border-gray-300 focus:ring-primary"
          aria-labelledby="privacy-setting-2-label"
          aria-describedby="privacy-setting-2-description"
        />
        <div class="ml-3 flex flex-col">
          <span
            id="privacy-setting-2-label"
            class="text-gray-900 block text-sm font-medium"
          >
            {controlsTranslation.notAvailable}
          </span>
        </div>
      </label>
    {/if}
  </div>
</fieldset>
