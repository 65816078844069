<script>
  import RadioGroup from './RadioGroup.svelte';

  export let question;
  export let value;
  export let index;
  export let onSelect;
  export let onShowNextRow;
  export let controlsTranslation;
</script>

<div class="Question">
  <p class="mb-2">{question.content}</p>
  <RadioGroup
    {index}
    slug={question.slug}
    {value}
    onSelect={(slug, val) => {
      onSelect(slug, val);
      onShowNextRow();
    }}
    {controlsTranslation}
    optionalAnswer={question.optionalAnswer}
  />
</div>

<style>
  .Question {
    @apply flex flex-col justify-between py-3 text-left w-full md:w-1/2 lg:w-1/3 xl:w-1/4;
  }

  @media (min-width: 640px) {
    .Question {
      padding-right: 0px;
    }
  }
  @media (min-width: 768px) {
    .Question:not(:nth-child(2n)) {
      padding-right: 20px;
    }
    .Question:nth-child(2n) {
      padding-right: 0px;
    }
  }
  @media (min-width: 1024px) {
    .Question:not(:nth-child(3n)) {
      padding-right: 20px;
    }
    .Question:nth-child(3n) {
      padding-right: 0px;
    }
  }
  @media (min-width: 1280px) {
    .Question:not(:nth-child(4n)) {
      padding-right: 20px;
    }
    .Question:nth-child(4n) {
      padding-right: 0px;
    }
  }
</style>
