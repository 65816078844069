<script>
  export let title = '';
</script>

<div class="mt-8 mx-auto text-center 2xl:w-5/12 lg:w-1/2 md:w-9/12">
  <h3 class="text-xl text-primary font-bold uppercase">
    {title}
  </h3>
  <p class="mt-2.5 text-gray-500">
    <slot />
  </p>
</div>
