<script>
  import { onMount } from 'svelte';
  import { ArrowLeft, ArrowRight, Upload } from 'svelte-hero-icons';
  import Header from './components/Header.svelte';
  import Progress from './components/Progress/Progress.svelte';
  import QuestionContainer from './components/QuestionContainer.svelte';
  import Button from './components/Button.svelte';
  import ImageUploader from './components/ImageUploader.svelte';
  import TextMessage from './components/TextMessage.svelte';
  import LoadingSpinner from './components/LoadingSpinner.svelte';
  import StatusList from './components/Status/StatusList.svelte';
  import Toast from './components/Toast.svelte';
  import Modal from './components/Modal.svelte';

  import {
    getCMSContent,
    getAdditionalInfo,
    postAdditionalInfo,
  } from './api/api';
  import { SUPPORTED_LANGUAGES, FINAL_STEP } from './constants/constants';
  import {
    extractContentString,
    formatVehicleInfoToDisplay,
    lightenDarkenColor,
  } from './utils/utils';

  import brandedVersions from '../public/brandedVersions.json';

  const steps = [];
  const questions = [];
  const imageUploaderContent = {
    registrationDocuments: {
      title: '',
      description: '',
      thumb: '/assets/thumbnails/registration-doc.jpg',
    },
    photos: [
      {
        slug: 'front',
        thumb: '/assets/thumbnails/front.jpg',
        alignmentLine: 'default',
      },
      {
        slug: 'back',
        thumb: '/assets/thumbnails/back.jpg',
        alignmentLine: 'default',
      },
      {
        slug: 'side_left',
        thumb: '/assets/thumbnails/side-left.jpg',
        alignmentLine: 'side',
      },
      {
        slug: 'side_right',
        thumb: '/assets/thumbnails/side-right.jpg',
        alignmentLine: 'side',
      },
      {
        slug: 'obliquely',
        thumb: '/assets/thumbnails/obliquely.jpg',
        alignmentLine: 'obliquely',
      },
      {
        slug: 'interior_front',
        thumb: '/assets/thumbnails/interior_front.jpg',
      },
      {
        slug: 'interior',
        thumb: '/assets/thumbnails/interior.jpg',
      },
      {
        slug: 'speedometer',
        thumb: '/assets/thumbnails/speedometer.jpg',
      },
      {
        slug: 'trip_computer_engine_on',
        thumb: '/assets/thumbnails/trip_computer_engine_on.jpg',
      },
    ],
  };

  let CMSContent = {};
  let logo;
  let lang = 'de';
  let submissionID = null;
  let isLoading = true;
  let isWrongPath = false;
  let answers = {};
  let statusInfo = [];
  let registrationDocuments = [];
  let photos = [];
  let vehicleInfo = {};
  let filestackClient = null;
  let currentStep = 0;
  let showToast = false;
  let showModal = true;
  let isStatusModal = false;
  let allPhotosUploadDone = false;

  onMount(() => {
    try {
      fetchCMSContent().then(() => {
        extractIDfromPath();

        if (isWrongPath) {
          isLoading = false;
          return;
        }

        fetchAdditionalInfo();
        filestackClient = filestack.init(_process.env.FILESTACK_API_KEY);
      });
    } catch {}
  });

  const getContentString = (key, content = null) => {
    if (Object.keys(CMSContent).length === 0) {
      return '';
    }

    return extractContentString(content ? content : CMSContent, key, lang);
  };

  const setColorTheme = (color) => {
    document.documentElement.style.setProperty(
      '--color-primary--dark',
      lightenDarkenColor(color, -65)
    );
    document.documentElement.style.setProperty('--color-primary', color);
    document.documentElement.style.setProperty(
      '--color-primary--light',
      lightenDarkenColor(color, 160)
    );
  };

  const fetchCMSContent = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const langParam = urlParams.get('lang');
    const id = urlParams.get('id');

    if (!id) {
      alert('No id attribute!');
      return;
    }

    if (SUPPORTED_LANGUAGES.indexOf(langParam) !== -1) {
      lang = langParam;
    }

    return getCMSContent(id).then((res) => {
      if (!res || !res.upload_area || !res.upload_area_questions) return;

      logo = res.brand.logo.url;
      document.querySelector('[rel=icon]').href = res.brand.favicon.url;
      setColorTheme(res.brand.colors.primary);
      CMSContent = res.upload_area;

      res.upload_area_questions.map((el, i) => {
        questions[i] = {
          content: getContentString(el.key, res.upload_area_questions),
          slug: el.key,
          optionalAnswer: el.optionalAnswer,
        };
      });

      steps[0] = {
        title: getContentString('step_questions_title'),
        description: getContentString('step_questions_description'),
      };
      steps[1] = {
        title: getContentString('step_documents_title'),
        description: getContentString('step_documents_description'),
      };
      steps[2] = {
        title: getContentString('step_photos_title'),
        description: getContentString('step_photos_description'),
      };

      const registrationDocuments = imageUploaderContent.registrationDocuments;
      registrationDocuments.title = getContentString(
        'upload_registration_document_title'
      );
      registrationDocuments.description = getContentString(
        'upload_registration_document_description'
      );

      const photos = imageUploaderContent.photos;
      photos.map((photo, i) => {
        photos[i].title = getContentString(`upload_photo_${photo.slug}_title`);
        photos[i].description = getContentString(
          `upload_photo_${photo.slug}_description`
        );
      });
    });
  };

  const fetchAdditionalInfo = () =>
    getAdditionalInfo(submissionID).then((data) => {
      isLoading = false;

      if (!data) {
        isWrongPath = true;
        showModal = false;
        return;
      }

      vehicleInfo = formatVehicleInfoToDisplay(data.vehicleInfo);

      let i = 0;

      // TODO: temp fix after AS24 BE updates. It only returns the key of current status
      const status = [
        {
          key: 'data_submitted',
          value: false,
          internal_data: {
            editor: '',
            date: '',
            note: '',
          },
        },
        {
          key: 'offer_sent',
          value: false,
          internal_data: {
            editor: '',
            date: '',
            note: '',
          },
        },
        {
          key: 'customer_decided',
          value: false,
          internal_data: {
            editor: '',
            date: '',
            note: '',
          },
        },
        {
          key: 'purchase_done',
          value: false,
          internal_data: {
            editor: '',
            date: '',
            note: '',
          },
        },
      ].map((el) => (el.key === data.status ? { ...el, value: true } : el));

      for (let key in status) {
        statusInfo[i] = {
          title: getContentString(`status_item_${status[key].key}_title`),
          description: getContentString(
            `status_item_${status[key].key}_description`
          ),
          isActive: status[key].value,
        };
        i++;
      }

      if (!data.answers || !Object.keys(data.answers).length) {
        steps.map((_, i) => (steps[i].isCompleted = false));
        return;
      }

      answers = data.answers;
      steps[0].isCompleted = true;

      registrationDocuments = data.registration_documents
        ? data.registration_documents
        : [];
      steps[1].isCompleted = registrationDocuments.length > 0;

      photos = data.photos ? data.photos : [];
      steps[2].isCompleted = data.submitted;

      allPhotosUploadDone = photos.filter((el) => el && el.url).length === 9;

      setInitialStep();

      if (currentStep === FINAL_STEP) {
        isStatusModal = true;
        showModal = !localStorage.getItem('success_modal_accepted');
      }
    });

  const setInitialStep = () => {
    const initialStep = steps.findIndex((step) => !step.isCompleted);

    if (initialStep !== -1) {
      currentStep = initialStep;
    } else {
      currentStep = FINAL_STEP;
    }
  };

  const extractIDfromPath = () => {
    const path =
      location.pathname.slice(-1) === '/'
        ? location.pathname.slice(0, -1)
        : location.pathname;
    const pathParts = path ? path.split('/u/') : null;

    if (!path || pathParts[0] || pathParts[1].split('/').length > 1) {
      isWrongPath = true;
      showModal = false;
      return;
    }

    submissionID = pathParts[1];
  };

  const onNextStep = (step) => {
    if (step !== undefined) {
      currentStep = step;
      return;
    }

    steps[currentStep].isCompleted = true;
    currentStep++;
  };

  const openUploadWindow = (types, data) => {
    const options = {
      lang,
      disableTransformer: true,
      fromSources: ['local_file_system'],
      maxFiles: 1,
      accept: types,
      uploadInBackground: false,
      onUploadDone: (res) => onFilestackUploadDone(res, data),
    };

    if (!types) {
      delete options.accept;
    }

    filestackClient.picker(options).open();
  };

  const onFilestackUploadDone = (res, data = {}) => {
    const imageObject = { url: res.filesUploaded[0].url };

    if (currentStep === 1) {
      registrationDocuments = [imageObject];
      gtag('event', 'click', {
        event_category: 'upload_area',
        event_label: 'upload_area_registration_document',
      });
      onSubmit(currentStep);
    } else {
      const { photoIndex } = data;
      postAdditionalInfo({
        photo: { ...imageObject, index: photoIndex },
        submission_id: submissionID,
      }).then(() => {
        showToast = true;

        if (photos.filter((el) => el && el.url).length === 9) {
          allPhotosUploadDone = true;
        }

        gtag('event', 'click', {
          event_category: 'upload_area',
          event_label: `upload_area_${imageUploaderContent.photos[photoIndex].slug}`,
        });
      });

      const updPhotos = [...photos];
      updPhotos[photoIndex] = imageObject;

      photos = updPhotos;
    }
  };

  const handleAnswerSelect = (slug, value) => {
    if (answers[slug] !== value) {
      gtag('event', 'click', {
        event_category: 'upload_area',
        event_label: `upload_area_${slug}`,
      });
    }

    answers[slug] = value;
  };

  const onSubmit = (step) => {
    isLoading = true;

    let data;

    switch (step) {
      case 0: {
        data = {
          answers,
          submission_id: submissionID,
        };
        break;
      }
      case 1: {
        data = {
          registration_documents: registrationDocuments,
          submission_id: submissionID,
        };
        break;
      }
      case 2: {
        data = {
          is_submission_done: true,
          submission_id: submissionID,
        };

        gtag('event', 'click', {
          event_category: 'upload_area',
          event_label: 'upload_area_submission',
        });

        break;
      }
      default: {
        isLoading = false;
        return;
      }
    }

    return postAdditionalInfo(data)
      .then(() => fetchAdditionalInfo)
      .then(() => {
        onNextStep();
        isLoading = false;
        showToast = true;

        if (step === 2) {
          statusInfo[0].isActive = true;
          isStatusModal = true;
          showModal = true;
        }
      });
  };
</script>

<Toast
  message={CMSContent && getContentString('success_message_data_saved')}
  isOpen={showToast}
  onDismiss={() => (showToast = false)}
/>
{#if isLoading}
  <div
    id="widget"
    class="flex items-center justify-center w-full h-full py-8 px-6 bg-white rounded-lg shadow sm:px-12"
  >
    <LoadingSpinner />
  </div>
{:else}
  <Modal
    isOpen={showModal}
    title={getContentString(
      isStatusModal ? 'success_message_title' : 'welcome_modal_title'
    )}
    description={getContentString(
      isStatusModal
        ? 'success_message_description'
        : 'welcome_modal_description'
    )}
    btnText={getContentString(
      isStatusModal ? 'success_message_button' : 'welcome_modal_button'
    )}
    onClose={() => {
      if (isStatusModal) {
        localStorage.setItem('success_modal_accepted', true);
      }

      showModal = false;
    }}
  />
  <div
    id="widget"
    class="w-full h-full py-8 px-6 bg-white rounded-lg shadow sm:px-12"
  >
    <Header
      {logo}
      logoOnly={isWrongPath || currentStep === FINAL_STEP}
      title={getContentString('main_title')}
      description={getContentString('main_description')}
      {vehicleInfo}
    />
    {#if isWrongPath}
      <TextMessage title={getContentString('error_message_invalid_id_title')}>
        {getContentString('error_message_unvalid_id_description').split(
          '\n'
        )[0]}
        <div>
          <a
            href="mailto:{getContentString(
              'error_message_unvalid_id_description'
            ).split('\n')[1]}"
            class="underline"
            >{getContentString('error_message_unvalid_id_description').split(
              '\n'
            )[1]}</a
          >
        </div></TextMessage
      >
    {:else if currentStep === FINAL_STEP}
      <StatusList
        title={getContentString('status_page_title')}
        description={getContentString('status_page_description')}
        vehicleInfo={{
          ...vehicleInfo,
          photo: photos[4] && photos[4].url ? photos[4].url : '',
        }}
        {statusInfo}
      />
    {:else}
      <Progress {steps} {currentStep} {onNextStep} />
      {#if currentStep === 0}
        <QuestionContainer
          {questions}
          {answers}
          {getContentString}
          {handleAnswerSelect}
          {onSubmit}
        />
      {:else if currentStep === 1}
        <div class="flex justify-center flex-wrap">
          <ImageUploader
            onUpload={() => openUploadWindow(['.pdf', 'image/*'])}
            title={imageUploaderContent.registrationDocuments.title}
            description={imageUploaderContent.registrationDocuments.description}
            img={registrationDocuments[0] ? registrationDocuments[0].url : null}
            thumb={imageUploaderContent.registrationDocuments.thumb}
            btnText={getContentString('upload_button')}
          />
        </div>
        <div class="mt-3 w-full flex justify-between items-center">
          <Button
            color="gray-300"
            icon={ArrowLeft}
            onClick={() => onNextStep(0)}
            >{getContentString('button_back')}</Button
          >
          <Button
            icon={ArrowRight}
            alignIcon="right"
            onClick={() => {
              onNextStep();
            }}
            disabled={!steps[1].isCompleted}
          >
            {getContentString('button_next')}</Button
          >
        </div>
      {:else if currentStep === 2}
        <div class="flex justify-center flex-wrap">
          {#each imageUploaderContent.photos as photo, i}
            <ImageUploader
              onUpload={() => openUploadWindow(['image/*'], { photoIndex: i })}
              title={photo.title}
              description={photo.description}
              img={photos[i] ? photos[i].url : ''}
              thumb={photo.thumb}
              alignmentLine={photo.alignmentLine}
              btnText={getContentString('upload_button')}
            />
          {/each}
        </div>
        <div class="mt-3 flex align-center justify-between w-full">
          <Button
            color="gray-300"
            icon={ArrowLeft}
            onClick={() => onNextStep(1)}
            >{getContentString('button_back')}</Button
          >
          <Button
            icon={Upload}
            alignIcon="right"
            onClick={() => onSubmit(2)}
            disabled={!allPhotosUploadDone}
            >{getContentString('button_submit')}</Button
          >
        </div>
      {/if}
    {/if}
  </div>
{/if}

<style>
  :global(:root) {
    --color-primary--dark: transparent;
    --color-primary: transparent;
    --color-primary--light: transparent;
  }
</style>
